import { Directive, HostListener, Input } from '@angular/core';
// import { ConfigGaService } from '../services/config-ga.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Directive({
	selector: '[appGaClick]',
	standalone: true
})
export class GaClickDirective {
	@Input() gaClickCategory = '';
	@Input() gaClickAction = '';
	@Input() gaClickLabel = '';
	@Input() gaClickValue = 0;

	constructor(
		// private readonly configGaService: ConfigGaService,
		private readonly cookieService: SsrCookieService
	) {}

	@HostListener('click', ['$event.target']) onClick(target: any): void {
		const isGaEnabled = this.cookieService.get('isGaEnabled') === 'true';

		if (isGaEnabled) {
			// this.configGaService.emitEvent({
			// 	category: this.gaClickCategory || 'General',
			// 	action: this.gaClickAction || 'Click',
			// 	label: this.gaClickLabel || target.innerText,
			// 	value: this.gaClickValue || 1
			// });
		}
	}
}
